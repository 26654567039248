import * as React from 'react'
import classNames from 'classnames'
import SEO from '../components/SEO'
import Layout from '../components/Layout/Layout'
import PageContent from '../components/PageContent/PageContent'
import * as styles from '../styles/regulamin.module.scss'

const regulaminContent = [
  'Umawiając psa na wizytę w Salonie psiej urody Hekate Klient akceptuje warunki niniejszego regulaminu.',
  'Usługi w salonie są wykonywane przez certyfikowanych groomerów z odpowiednim wyszkoleniem.',
  'Obowiązuje punktualne przychodzenie na wizytę. Na każdego klienta przeznaczona jest konkretna ilość czasu i każde 15 minut spóźnienia przesuwa wizyty innych klientów tego dnia, do czego nie można dopuścić.',
  'W przypadku rezygnacji, zmiany terminu lub godziny wizyty, Klient powinien poinformować salon o tym minimum 1 dzień wcześniej przed wyznaczoną wizytą.',
  'Prosimy o jak najszybszą informację telefoniczną w przypadku spóźnienia się na umówioną wizytę.',
  'Obsługiwane są wyłącznie psy zdrowe, z aktualnym szczepieniem na wściekliznę. Podczas wizyt w salonie Klient powinien posiadać do wglądu książeczkę zdrowia zwierzęcia.',
  'Przed wizytą pies powinien zostać wyprowadzony na spacer, aby załatwił potrzeby fizjologiczne. Ważne jest, aby nie karmić psa krótko przed przybyciem do salonu.',
  'Wszystkie psy są kąpane w salonie w trakcie pielęgnacji. Tylko właściwie oczyszczona skóra i sierść psa, pozwala na odpowiednie strzyżenie. Kąpiel przed zabiegiem pielęgnacyjnym w znacznym stopniu ułatwia pracę groomerowi oraz pozwala na wykonanie usługi na jak najwyższym poziomie.',
  'W wyjątkowych przypadkach, jeżeli na kąpiel nie pozwala stan psa (np. jest bardzo stary, co byłoby dla niego zbyt dużym stresem), koszt takiej usługi jest taki sam jak pełnej usługi z kąpielą.',
  'Pies zachowuje się lepiej, gdy zostaje sam na sam z groomerem. Decydując się na zabieg w salonie Hekate Klient musi być przygotowany na to, że trzeba będzie go zostawić w salonie samemu na czas wykonywanej usługi. Zabieg przechodzi wtedy o wiele szybciej i sprawniej. W szczególnych przypadkach właściciel czworonoga może zostać w salonie, decyduje o tym groomer.',
  'Właściciel ma obowiązek poinformować groomera, jeśli u psa występują agresywne zachowania. Psom agresywnym zakładany jest kaganiec. W przypadku, gdy pies nadal nie pozwala się opanować, groomer może zrezygnować z dalszego wykonywania usługi. Pobierana jest wtedy opłata jak za całą usługę, na którą umówiony był Klient.',
  'Ze względu bezpieczeństwa sanitarnego psy z widocznymi objawami chorób skóry nie są obsługiwane. Odsyłamy je do przychodni weterynaryjnej i zalecamy Klientom umówienie zwierzęcia na pielęgnację dopiero po całkowitym wyleczeniu.',
  'Groomer ma prawo odmówić usługi psa zapchlonego ze względu na ryzyko przejścia pcheł na innych psich klientów.',
  'Podczas rezerwacji wizyty, obowiązkiem Klienta jest poinformowanie o złym stanie szaty psa (tj. kołtunach, filcu), co pozwoli zarezerwować odpowiednio większą ilość czasu.',
  'Rozczesywanie kołtunów lub ścinanie filcu jest dodatkowo płatne 60zł za godzinę pracy.',
  'W salonie przestrzegamy zasad BHP oraz regularnie dezynfekujemy wszystkie narzędzia oraz powierzchnie użytkowe środkami do tego przeznaczonymi.',
  'W salonie używamy szamponów znanych marek tylko i wyłączenie przeznaczonych do pielęgnacji zwierząt.',
  'Nie strzyżemy psów z podwójną okrywą włosową.',
  'Pośpiech w pracy groomera jest niewskazany, pracujemy przy użyciu ostrych narzędzi, prosimy zatem o nie wywieranie presji czasu oraz nie dekoncentrowanie groomera ani psa podczas zabiegów.',
  'Klient ma obowiązek poinformować groomera, jeśli pies cierpi na jakiekolwiek choroby lub alergie. Salon nie ponosi odpowiedzialności za pogorszenie się stanu zdrowia psa, jeśli Właściciel zataił chorobę.',
  'W salonie nie używamy żadnych środków uspokajających, prosimy również o niepodawanie samodzielnie psu takich środków przed zabiegiem. Jeśli środki zostały podane przez weterynarza z jego zalecenia obowiązkiem właściciela psa jest poinformowanie o tym groomera przed zabiegiem.',
  'W salonie Hekate obowiązuje cennik, który dostępny jest do wglądu na funpage’u na Facebook’u oraz stacjonarnie w salonie. Koszt usługi może zostać podwyższony, jeśli sierść psa okażę się być bardzo zaniedbana.',
  'Po odbiór psa należy przyjść po uprzednim telefonie z salonu, odebrać powinna ta sama osoba, która przyprowadziła psa lub osoba przez nią wskazana.',
  'Wizyta w naszym salonie jest jednoznaczną zgodą na publikację wizerunku psa w naszych galeriach.',
  'Państwa dane osobowe przetwarzane są w celu świadczenia usługi groomerskiej zapewniającej legalności przetwarzania danych osobowych w sposób zgodny z wymogami RODO.',
  'Aby współpraca między Klientem, a salonem Hekate układała się pomyślnie, prosimy o dostosowanie się do powyższych zasad.'
]

function RegulaminPage() {
  return (
    <Layout>
      <SEO title="Regulamin" description="Regulamin salonu" />
      <div
        className={classNames(
          'h-screen relative max-w-[100vw] overflow-x-hidden',
          styles.container,
          styles.container__background
        )}
      >
        <PageContent title="REGULAMIN">
          <ol className="list-decimal p-3 pl-5">
            {regulaminContent.map(point => (
              <li key={point} className="pl-4 mb-4 last:mb-0">
                {point}
              </li>
            ))}
          </ol>
        </PageContent>
      </div>
    </Layout>
  )
}

export default RegulaminPage
